<template>
  <div>
    <b-card bg-variant="light" class="my-3">
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="3" :label="`${$t('filter')}:`" class="mb-0">
            <b-input-group>
              <b-form-input
                v-model="filterSearch"
                :placeholder="$t('type-to-search')"
                debounce="300"
              />
              <b-input-group-append v-if="filterSearch">
                <b-btn @click="filterSearch = ''">&times;</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label-cols="3" :label="`${$t('status')}:`" class="mb-0">
            <b-form-select v-model="filterStatus" :options="accountStatuses">
              <template slot="first">
                <option :value="null">-- {{ $t('all') }} --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label-cols="3" :label="`${$t('owner')}:`" class="mb-0">
            <b-form-select v-model="filterOwner" :options="owners">
              <template slot="first">
                <option :value="null">-- {{ $t('all') }} --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2" class="pt-2 d-flex justify-content-between">
          <b-form-checkbox v-model="filterUseAdvancedSearch">
            {{ $t('filters.advanced.toggle') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <advanced-search
        v-if="filterUseAdvancedSearch"
        v-model="filterAdvancedSearch"
        :filter-options="advancedFilterOptions"
      />
    </b-card>
    <b-pagination
      v-if="totalRows > perPage"
      :total-rows="totalRows"
      :per-page="perPage"
      v-model="currentPage"
      align="center"
    />
    <b-table
      show-empty
      striped
      stacked="md"
      :items="items"
      :busy="loading"
      :fields="tableFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDir"
      no-local-sorting
    >
      <template #thead-top>
        <tr>
          <th colspan="2">Partner</th>
          <th colspan="6" class="border-left">Jobs</th>
          <th colspan="2" class="border-left">Actions</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </template>
      <template #cell(account.name)="{ item }">
        <p>
          {{ item.account.name }}
          &nbsp;
          <a :href="dataUIAccountURL(item.account.id)" target="_blank">
            <i class="material-icons">edit</i>
          </a>
          <br />
          {{ item.account.shipping_address?.street }}
          <br />
          {{ item.account.shipping_address?.country_code }} -
          {{ item.account.shipping_address?.postal_code }}
          {{ item.account.shipping_address?.city }}
          <br />
          <template v-if="item.account.website">
            <a :href="websiteURL(item.account.website)" target="_blank">
              {{ item.account.website }}
            </a>
          </template>
          <br />
          <b-badge
            class="ml-1"
            :variant="partnerExperienceLevel(item.completed_jobs).variant"
          >
            {{ partnerExperienceLevel(item.completed_jobs).text }}
          </b-badge>
        </p>
        <table class="table table-borderless small w-auto">
          <tr class="bg-transparent">
            <td class="pl-0 py-1 pr-2">Alle Bewertungen:</td>
            <td class="p-0 py-1">
              <RatingWidget
                context="job"
                :count="item.rating_count ?? 0"
                :average="item.rating_avg"
              />
            </td>
          </tr>
          <tr class="bg-transparent">
            <td class="pl-0 py-1 pr-2">Letzte Bewertung:</td>
            <td class="p-0 py-1">
              <RatingWidget context="job" :average="item.rating_last" />
            </td>
          </tr>
        </table>
      </template>
      <template #cell(revenue)="{ value }">{{ value | currency }}</template>
      <template #cell(shown_jobs)="{ item, value }">
        <b-link @click="onCallResolved(item, 'shown')">{{ value }}</b-link>
      </template>
      <template #cell(potential_jobs)="{ item, value }">
        <b-link @click="onCallResolved(item, 'potential')">{{ value }}</b-link>
      </template>
      <template #cell(active_jobs)="{ item, value }">
        <b-link @click="onCallResolved(item, 'active')">{{ value }}</b-link>
      </template>
      <template #cell(completed_jobs)="{ item, value }">
        <b-link @click="onCallResolved(item, 'completed')">{{ value }}</b-link>
      </template>
      <template #cell(rejected_jobs)="{ item, value }">
        <b-link @click="onCallResolved(item, 'rejected')">{{ value }}</b-link>
      </template>
      <template #cell(cancelled_jobs)="{ item, value }">
        <b-link @click="onCallResolved(item, 'cancelled')">{{ value }}</b-link>
      </template>
      <template #cell(_actions)="{ item }">
        <b-row class="mx-0 mb-1">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="onCallResolved(item)"
          >
            <div class="contact-partner-btn-content">
              <span class="material-icons left-icon">perm_phone_msg</span>
              <span>Contact Partner</span>
            </div>
          </b-button>
        </b-row>
        <b-dropdown
          size="lg"
          variant="link"
          toggle-class="text-decoration-none p-0 text-dark"
          menu-class="menu-styling"
          no-caret
          right
        >
          <template #button-content>
            <span
              :class="`${
                isPartnerWatching(item)
                  ? 'material-icons'
                  : 'material-icons-outlined'
              } cursor-pointer heading-2`"
            >
              visibility
            </span>
          </template>
          <div class="d-block">
            <b-dropdown-item-button
              variant="outline-primary"
              class="text-left"
              aria-describedby="dropdown-header-2"
              @click="toggleWatching(item)"
            >
              {{ isPartnerWatching(item) ? 'Stop Watching' : 'Start Watching' }}
            </b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-row>
              <b-col>
                <span class="heading-3 font-weight-bold">
                  {{
                    item.watchlist.length === 0 ? 'No watchers yet' : 'Watchers'
                  }}
                </span>
              </b-col>
            </b-row>
            <template>
              <b-row v-for="(user, index) in item.watchlist" :key="index">
                <b-col cols="10" class="text-ellipsis">
                  <span class="heading-3">{{ user.name }}</span>
                </b-col>
                <b-col cols="2" class="text-right">
                  <span
                    class="material-icons cursor-pointer heading-3"
                    @click="unWatchPartner(item, user.sfid)"
                  >
                    close
                  </span>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-dropdown>
      </template>
      <template
        #cell(_details)="{ toggleDetails, detailsShowing }"
        colspan="100%"
      >
        <button
          @click.stop="toggleDetails"
          style="font-size: 1.9rem; background: transparent; border: 0"
          class="material-icons"
        >
          {{ detailsShowing ? 'expand_less' : 'expand_more' }}
        </button>
      </template>
      <template #row-details="{ item }">
        <partner-details :partner="item" />
      </template>
    </b-table>
    <b-alert v-if="error !== null" variant="danger" class="text-left" show>
      <h4 class="alert-heading">{{ $t('_errors.network.title') }}</h4>
      <p>{{ error.message }}</p>
      <b-collapse id="error-details" class="my-2">
        <b-card>
          <pre>{{ error.details }}</pre>
        </b-card>
      </b-collapse>
      <b-btn v-b-toggle.error-details>
        {{ $t('login.buttons.error-details') }}
      </b-btn>
    </b-alert>
    <b-pagination
      :total-rows="totalRows"
      :per-page="perPage"
      v-model="currentPage"
      align="center"
    />
    <div v-if="currentPageCount" class="text-center text-muted">
      <small>{{ currentPageCount }} / {{ totalRows }}</small>
    </div>
    <!--Partner Jobs Modal & Partner Reached Modal -->
    <PartnerJobsView
      :show="showJobPartnerModal"
      :activeTab="tabToShow"
      :partner="jobPartner"
      @close="onCallEnded"
    />
  </div>
</template>

<script>
import axios from 'axios';
import Api from '@gid/vue-common/api';
import {
  _partnerExperienceLevel,
  getPartnerExperienceRange,
} from '@gid/vue-common/utils';
import { SET_PARTNERS_FILTER } from '@/store/partners.module';
import { mapFilters } from '@gid/vue-common/store/utils';
import AdvancedSearch from '@gid/vue-common/components/filters/AdvancedSearch.vue';
import RatingWidget from './RatingWidget.vue';
import RatingFilter from './RatingFilter.vue';
import PartnerWatcherFilter from './PartnerWatcherFilter.vue';
import PartnerJobsView from '@/components/partner/PartnerJobsView.vue';
import PartnerDetails from './PartnerDetails.vue';
import Vue from 'vue';
import { PartnerExperienceEnum, AccountStatusEnum } from '@gid/models';

Vue.component('rating-filter', RatingFilter);
Vue.component('partner-watcher-filter', PartnerWatcherFilter);

export default {
  components: {
    AdvancedSearch,
    PartnerDetails,
    PartnerJobsView,
    RatingWidget,
  },
  data() {
    return {
      tableFields: [
        {
          label: '',
          key: 'account.name',
        },
        {
          label: 'Revenue',
          key: 'revenue',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: 'Shown',
          key: 'shown_jobs',
          thClass: 'border-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: 'Potential',
          key: 'potential_jobs',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: 'Active',
          key: 'active_jobs',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: 'Done',
          key: 'completed_jobs',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: 'Rejected',
          key: 'rejected_jobs',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: 'Cancelled',
          key: 'cancelled_jobs',
          thClass: 'border-right',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: '',
          key: '_actions',
          class: 'text-left',
        },
        {
          label: '',
          key: '_details',
          class: 'text-left',
          tdClass: 'align-middle',
          tdAttr: { colspan: '100%' },
        },
      ],
      accountStatuses: Object.values(AccountStatusEnum),
      items: [],
      currentPage: 1,
      currentPageCount: null,
      perPage: 10,
      totalRows: 0,
      sortBy: null,
      sortDir: false,
      loading: false,
      tabToShow: 'shown',
      owners: [],
      error: null,
      advancedFilterOptions: [
        {
          label: 'Location',
          type: 'point_with_radius',
          component: 'geo',
        },
        {
          type: 'hwo_a',
          label: 'HwO A',
          picklistName: 'HwO_A',
          component: 'CrudPicklistMultiSelect',
          multiple: true,
        },
        {
          type: 'hwo_b1',
          label: 'HwO B1',
          picklistName: 'HwO_B1',
          component: 'CrudPicklistMultiSelect',
          multiple: true,
        },
        {
          type: 'hwo_b2',
          label: 'HwO B2',
          picklistName: 'HwO_B2',
          component: 'CrudPicklistMultiSelect',
          multiple: true,
        },
        {
          type: 'kein_hwo',
          label: 'Kein HwO',
          picklistName: 'nicht_HwO',
          component: 'CrudPicklistMultiSelect',
          multiple: true,
        },
        {
          type: 'zusatzqualifikation',
          label: 'Zusatzqualifikation',
          picklistName: 'Zertifikat_Zusatzqualifikation',
          component: 'CrudPicklistMultiSelect',
          multiple: true,
        },
        {
          type: 'weiterbildung',
          label: 'Brand Training',
          picklistName: 'Weiterbildung',
          component: 'CrudPicklistMultiSelect',
          multiple: true,
        },
        {
          type: 'transporter',
          label: 'Transporter',
          picklistName: 'Transporter',
          component: 'CrudPicklistMultiSelect',
          multiple: true,
        },
        {
          label: this.$i18n.t('filters.partner-experience'),
          type: 'partner-experience',
          component: 'filter-multiselect',
          values: Object.values(PartnerExperienceEnum).map((value) => {
            const [lowerRange, upperRange] = getPartnerExperienceRange(value);
            return {
              text: value,
              value: JSON.stringify({
                [`partner-experience_from`]: lowerRange,
                [`partner-experience_to`]: upperRange,
              }),
            };
          }),
          multiple: false,
        },
        {
          label: 'Partner Average Rating',
          type: 'rating_avg',
          component: 'rating-filter',
        },
        {
          type: 'watcher',
          label: 'Watched Partners',
          component: 'partner-watcher-filter',
          multiple: true,
        },
      ],
      showJobPartnerModal: false,
      jobPartner: {},
    };
  },
  computed: {
    ...mapFilters({
      moduleName: 'partners',
      states: [
        'search',
        'status',
        'owner',
        'useAdvancedSearch',
        'advancedSearch',
      ],
      setMutation: SET_PARTNERS_FILTER,
      setCallback: 'null',
    }),
    null() {
      return null;
    },
    dataUIAccountURL() {
      return (accountId) => {
        return `./data-ui/Account/${accountId}`;
      };
    },
    partnerExperienceLevel() {
      return _partnerExperienceLevel;
    },
    websiteURL() {
      return (website) => {
        if (website.startsWith('http://') || website.startsWith('https://')) {
          return website;
        }
        return `https://${website}`;
      };
    },
  },
  watch: {
    currentPage() {
      clearTimeout(this.$_timeout);
      this.$_timeout = setTimeout(() => {
        this.loadItems();
      }, 250);
    },
    sortBy() {
      this.loadItems();
    },
    sortDir() {
      this.loadItems();
    },
    filterSearch(nextVal, prevVal) {
      if (
        (prevVal.trim() !== nextVal.trim() && nextVal.length > 1) ||
        nextVal.length == 0
      ) {
        this.filterUpdated();
      }
    },
    filterAdvancedSearch(nextVal, prevVal) {
      if (JSON.stringify(prevVal) !== JSON.stringify(nextVal)) {
        this.filterUpdated();
      }
    },
    filterStatus(nextVal, prevVal) {
      if (prevVal !== nextVal) {
        this.filterUpdated();
      }
    },
    filterOwner(nextVal, prevVal) {
      if (prevVal !== nextVal) {
        this.filterUpdated();
      }
    },
  },
  created() {
    this.$_timeout = null;
    this.loadItems();
    axios.get(`/api/admin/owners/all`).then((response) => {
      this.owners = response.data.map((item) => ({
        value: item.sfid,
        text: item.name,
      }));
    });
  },
  beforeDestroy() {
    // Ensure that any pending timeout is cleared on destroy
    clearTimeout(this.$_timeout);
  },
  methods: {
    filterUpdated() {
      this.currentPage = 1;
      this.$nextTick(() => this.loadItems());
    },
    async loadItems() {
      const searchQuery = {
        page_size: this.perPage,
        page_number: this.currentPage - 1,
        search: this.filterSearch.trim(),
        status: this.filterStatus,
        owner: this.filterOwner,
        sort_by: this.sortBy ? this.sortBy : null,
        sort_dir: this.sortDir ? 'desc' : 'asc',
      };
      if (this.filterUseAdvancedSearch && this.filterAdvancedSearch) {
        searchQuery.search_json = JSON.stringify(this.filterAdvancedSearch);
      }
      this.loading = true;

      this.items = await Api.get(`/api/admin/partners`, {
        params: searchQuery,
        autoCancelScope: this,
      })
        .then((response) => {
          this.totalRows = response.data.pagination.total_count;
          this.currentPageCount = response.data.data.length;
          this.error = null;
          return response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
          return [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCallResolved(partner, tab = 'shown') {
      const {
        account: { id, name },
        completed_jobs,
      } = partner;
      this.jobPartner = {
        id,
        name,
        contacts: this.loadPartnerContacts(partner),
        completedJobs: completed_jobs,
      };
      this.tabToShow = tab;
      this.toggleContactsModal();
    },
    onCallEnded() {
      this.jobPartner = {};
      this.toggleContactsModal();
    },
    loadPartnerContacts(partner) {
      return partner.contacts
        .filter(
          (contact) =>
            contact &&
            contact.profiles.length > 0 &&
            (contact.phone || contact.mobile_phone) &&
            contact.email &&
            !contact.email.match(/(@getitdone.[rocks,co])\w+/g),
        )
        .map((item) => ({
          firstName: item.first_name,
          lastName: item.last_name,
          phone: item.phone || item.mobile_phone,
        }));
    },
    toggleContactsModal() {
      this.showJobPartnerModal = !this.showJobPartnerModal;
    },
    isPartnerWatching(partner) {
      return partner.watchlist.some(
        (elem) => elem.sfid === this.$store.state.auth.user.contact.sfid,
      );
    },
    toggleWatching(partner) {
      const isWatching = this.isPartnerWatching(partner);
      if (isWatching) {
        this.unWatchPartner(partner, this.$store.state.auth.user.contact.sfid);
      } else {
        this.watchPartner(partner);
      }
    },
    async watchPartner(partner) {
      this.loading = true;
      axios
        .post(`/data-api/partner-watchlist`, {
          partnerId: partner.account.id,
        })
        .catch((error) => {
          this.handleError(error);
        });
      this.loadItems();
    },
    async unWatchPartner(partner, adminId) {
      this.loading = true;
      axios
        .post(`/data-api/partner-watchlist/unwatch`, {
          partnerId: partner.account.id,
          adminId,
        })
        .catch((error) => {
          this.handleError(error);
        });
      this.loadItems();
    },
    handleError(error) {
      const err = { details: error };
      if (error.response) {
        err.message = error.response.data.msg || error.response.data;
      } else if (error.request) {
        err.message = this.$t('_errors.network.details');
      } else {
        err.message = error.message;
      }
      this.error = err;
    },
  },
};
</script>
<style scoped lang="scss">
.contact-partner-btn-content {
  display: inline-block;
  margin-right: 0.5rem;

  & > .left-icon {
    font-size: 1.1rem;
    vertical-align: sub;
    margin-right: 0.5rem;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.heading-2 {
  font-size: 2rem;
}
.heading-3 {
  font-size: 1rem;
}
::v-deep .menu-styling {
  width: 250px;
  padding: 0.5rem;
}
</style>
